import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { listResponseSelector, keyObjectDataSelector } from '../../../../redux/selectors';
import {
    handleChangesKeyDataAction,
    onClickEditFab,
} from '../../../../redux/actions/subdealApiAction';
import View from './view';

const Index = (props) => {
    const subDeal = useSelector((state) => listResponseSelector(state, 'subdealApiReducer'));
    const basicData = useSelector((state) => keyObjectDataSelector(state, 'basic'));
    const backData = useSelector((state) => keyObjectDataSelector(state, 'back'));
    const frontData = useSelector((state) => keyObjectDataSelector(state, 'front'));
    const dispatch = useDispatch();
    const [indexTab, setIndexTab] = useState(0);
    const handleChangeTab = (event, newValue) => {
        setIndexTab(newValue);
    };

    if (!subDeal) {
        return <div />;
    }
    const handleChangeTextField = (e, keyObject, indexArray) => {
        let value = e.target.value;
        let firstLatterTarget = '';
        if (keyObject == 0 || keyObject == 1) {
            firstLatterTarget = e.target.name.charAt(0);
            if (firstLatterTarget == 'c' || firstLatterTarget == 'p') {
            }
        } else {
            firstLatterTarget = e.target.name.slice(0, 2);
            if (firstLatterTarget == 'SF' || firstLatterTarget == 'un') {
            }
        }
        dispatch(handleChangesKeyDataAction(e.target.name, value, keyObject, indexArray));
    };
    const onEdit = () => {
        dispatch(onClickEditFab());
    };
    return (
        <View
            basicData={basicData}
            backData={backData}
            frontData={frontData}
            indexTab={indexTab}
            handleChangeTab={handleChangeTab}
            handleChangeTextField={handleChangeTextField}
            onEdit={onEdit}
        />
    );
};

Index.propTypes = {};

export default Index;
