export const mapSubdeal = {
    DASO: { title: 'Date', value: '' },
    DETY: { title: 'Type', value: '' },
    FIMA: { title: 'FIM', value: '' },
    FIWI: { title: 'Deal', value: '' },
    NEUS: { title: 'NeUs', value: '' },
    SANO1: { title: 'Associate 1', value: '' },
    SANO2: { title: 'Associate 2', value: '' },
    SF: { title: 'Special Finance', value: '' },
    ST: { title: 'Status', value: '' },
    assoc_number: { title: 'Associate Num ', value: '' },
    assoc_pre_comm: { title: 'Book Comm', value: '' },
    unit: { title: 'Unit', value: '' },
};
