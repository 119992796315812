import {
    SUBDEAL_API_START_SEARCH,
    SUBDEAL_API_CHANGES_KEY_DATA,
    SUBDEAL_API_EDIT_START,
    SUBDEAL_API_DELETE_START,
    SUBDEAL_API_REVERSAL_SEARCH_START,
    SET_SUBDEAL_ON_INTIAL_STATE,
} from '../../consts/actionTypes';
export const detailSubdealAction = (associate, subdeal) => ({
    type: SUBDEAL_API_START_SEARCH,
    associate,
    subdeal,
});
export const handleChangesKeyDataAction = (targetName, targetValue, keyObject, indexArray) => ({
    type: SUBDEAL_API_CHANGES_KEY_DATA,
    targetName,
    targetValue,
    keyObject,
    indexArray,
});
export const onClickEditFab = () => ({
    type: SUBDEAL_API_EDIT_START,
});
export const onDeleteSubDealAction = (associate, subdeal) => ({
    type: SUBDEAL_API_DELETE_START,
    associate,
    subdeal,
});
export const onReverseSubdealAction = (payload) => ({
    type: SUBDEAL_API_REVERSAL_SEARCH_START,
    payload,
});
export const onSetIntialStateSubdealAction = (payload) => ({
    type: SET_SUBDEAL_ON_INTIAL_STATE,
    payload,
});
