import React from 'react';
import { Description } from '@material-ui/icons';
import { DATA, ADJUSTMENT_DATA, SUBDEALS, TEAM_MANAGER_DRAW } from '../../../../../../consts/urls';

export const messageButtons = {
    title: 'DMS Extraction',
    icon: <Description />,
    children: [
        {
            title: 'Deals By Month',
            icon: <Description />,
            action: 'term',
            endPoint: DATA,
            text: 'Please Select Month And Year To Extract Deals',
        },
        {
            title: 'Deals By number',
            icon: <Description />,
            action: 'deal',
            endPoint: DATA,
            text: 'Enter Deal Number To Extract It',
        },
        {
            title: 'Adjustments By Month',
            icon: <Description />,
            action: 'term',
            endPoint: ADJUSTMENT_DATA,
            text: 'Please Select Month And Year To Extract Adjustments',
        },
        {
            title: 'Create Sub Deals By Month',
            icon: <Description />,
            action: 'term',
            endPoint: SUBDEALS,
            text: 'Please Select Month And Year To Create Sub Deals',
        },
        {
            title: 'Create TM Draws By Month',
            icon: <Description />,
            action: 'termTMDraws',
            endPoint: TEAM_MANAGER_DRAW,
            text: 'Please Select Month And Year To Create TM Draws',
        },
        /* {
        title: 'Adjustments by deal',
        icon: <Description />
      },
      {
        title: 'Adjustment by deal',
        icon: <Description />
      },*/
    ],
};
